import React from "react";
import "./cellebration.css";

const Cellebration = () => {
  return (
    <div className="cellebration ">
      <div className="left">
        <h2>Our Location</h2>
        <div>
          <iframe
            title="donderloc"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d64020.93285125486!2d34.73190834047799!3d0.28535901378777045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x17803df6a3847f75%3A0xdcac1db998d6613d!2sDonder%20co.%20LTD!5e0!3m2!1sen!2ske!4v1671704062832!5m2!1sen!2ske"
            width="250"
            height="170"
            style={{ border: "0", marginBottom: "2vh" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <span>Kakamega Opposite School of the Deaf-Mwiyala Lurambi road.</span>
      </div>

      <div className="center">
        <h2>Things We Do</h2>
        <li>Concrete Works</li>
        <li>Civil Engineering Works</li>
        <li>Supply of Construction Materias</li>
        <li>Project Management</li>
        <li>Metal Works</li>
      </div>
      <div className="right">
        <h2>We Are Proudly</h2>
        <img src="./images/years.png" alt="" />
        <span>
          Cellebrating 11 Years of Providing Exellent Services to our Clients
        </span>
      </div>
    </div>
  );
};

export default Cellebration;
