import React from "react";
import Back from "../../components/reusable/back/Back";
import img from "../../images/screen/screen3.png";
import values from "../../images/values.png";

import "./services.css";

const Services = () => {
  return (
    <section>
      <Back name="Our Services" title="we offer the following" cover={img} />
      <div className="services">
        <div className="mandv">
          <div className="justify">
            <h2>Why Us ?</h2>
            <p>
              Welcome to the world of Donder Company, where we believe that
              simplicity and customer satisfaction go hand in hand. We recognize
              that construction projects can be intricate and demanding, but our
              mission is to make the entire process effortless and stress-free
              for you, our valued customer. We empathize with the unique
              challenges that arise when building in Kenya and the broader East
              Africa region, and our utmost priority is to provide you with an
              enjoyable, rewarding, and hassle-free construction experience.
            </p>
            <p>
              At Donder Company, we strive to create a seamless journey from
              start to finish. We understand that you have entrusted us with
              your vision and investment, and we take this responsibility to
              heart. Our team of seasoned professionals is well-versed in
              navigating the complexities of construction projects in this
              region. With their expertise and experience, they will guide you
              every step of the way, ensuring that your experience is smooth,
              efficient, and satisfying.
            </p>
            <p>
              From the moment you engage with us, we are committed to
              understanding your unique needs and aspirations. We value open
              communication and actively listen to your ideas, concerns, and
              preferences. By building a strong partnership based on trust and
              transparency, we create an environment where your voice is heard
              and your vision is realized.
            </p>
            <p>
              At Donder Company, we have carefully curated a network of reliable
              suppliers, skilled craftsmen, and industry-leading experts to
              support our commitment to excellence. We believe that quality
              should never be compromised, and we meticulously select materials
              and employ the latest construction techniques to deliver
              exceptional results that stand the test of time.
            </p>
            <p>
              We are dedicated to streamlining the construction process,
              minimizing disruptions, and maximizing efficiency. Our goal is to
              alleviate the burdens associated with construction, allowing you
              to focus on the excitement of seeing your project come to life.
              With our meticulous planning, attention to detail, and proactive
              problem-solving, you can trust that your project will progress
              smoothly, on schedule, and within budget.
            </p>
            <p>
              Join us at Donder Company and experience the pleasure of
              stress-free construction. Let us handle the complexities while you
              enjoy the journey towards transforming your dreams into reality.
              Contact us today and discover how we can make your construction
              experience truly remarkable.
            </p>
          </div>
          <div className="corea">
            <h2>Core Values</h2>
            <div className="flexible">
              <img src={values} alt="" />
              <div className="core">
                <p>
                  <li>Project Safety is our first priority</li>
                  <li>
                    Expectation - We will seek to exceed the expectation of
                    others
                  </li>
                  <li>Quality - We will deliver quality without compromise</li>
                  <li>
                    Customer Centered - Our success depends on our customers
                    success
                  </li>
                  <li>Integrity - We will treat others with respect</li>
                </p>
              </div>
            </div>

            <h2>Heath & Safety Policy</h2>

            <div className="safety">
              <p>
                At the heart of our principles lies a strong belief in
                preserving the quality of our air, water, and soil to ensure the
                sustained well-being of all ecosystems. We are resolute in our
                commitment to act responsibly, taking into careful consideration
                the impact of our operations and services on the environment.
                For us, protecting the environment is not a mere choice, but a
                solemn obligation that we embrace wholeheartedly. By doing so,
                we strive to meet the needs of both present and future
                generations, ensuring a harmonious coexistence with nature.
              </p>
              <p>
                Each day, our primary objective revolves around achieving zero
                incidents of lost time on every project we undertake. We hold
                the safety of our workforce in the highest regard, as we believe
                that every worker should have the opportunity to return home to
                their loved ones at the end of each day, unharmed. To fulfill
                this goal, we implement rigorous safety measures and protocols,
                fostering a work environment that prioritizes the well-being and
                security of every individual involved in our projects.
              </p>
            </div>
          </div>
          <div className="designs">
            <h2>Mission Statement</h2>
            <p>
              Our primary aim is to consistently exceed our customers'
              expectations by setting new standards in meticulous quality, cost
              management, and timely project completion. We strive to achieve
              excellence in every aspect of our service, ensuring that our
              customers are delighted at every stage of their project.
              Additionally, we are committed to expanding our resources and
              maintaining a substantial presence in the industry, so that we can
              continue to provide our clients with the necessary support and
              expertise to successfully develop and complete their projects.
            </p>

            <h2>Our Aim</h2>
            <p>
              Our objective is to continually enhance and advance our
              construction techniques to effectively address our clients' needs
              while maintaining affordable pricing and delivering high-quality
              results. We are dedicated to revolutionizing the construction
              industry by prioritizing innovation and research. By embracing the
              latest and most efficient methods in construction and investing in
              cutting-edge technology, our goal is to elevate the standard of
              quality in our field and provide our clients with the best
              possible outcomes.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
