import React from "react";
import Cellebration from "./cellebration/Cellebration";
import "./homepage.css";
import Helpyou from "./helpyou/Helpyou";
import Whyus from "./whyus/Whyus";
import Welcome from "./welcome/Welcome";
import Numbers from "./numbers/Numbers";
import Screen from "./screen/Screen";

const Homepage = () => {
  return (
    <div className="homepage">
      <Screen />
      <Numbers />
      <Welcome />
      <Helpyou />
      <Whyus />
      <Cellebration />
    </div>
  );
};

export default Homepage;
