import cover1 from "../../images/gallery/p-1.jpg";
import cover2 from "../../images/gallery/p-4.jpg";
import cover3 from "../../images/gallery/p-9.jpg";
import cover4 from "../../images/gallery/p-2.jpg";
import cover5 from "../../images/gallery/p-7.jpg";
import cover6 from "../../images/gallery/p-6.jpg";
import cover7 from "../../images/gallery/p-10.jpg";
import cover8 from "../../images/gallery/p-8.jpg";
import cover9 from "../../images/gallery/p-3.jpg";
import cover10 from "../../images/gallery/p-5.jpg";
import cover11 from "../../images/gallery/p-11.jpg";
import cover12 from "../../images/gallery/p-12.jpg";
import cover13 from "../../images/gallery/p-13.jpg";
import cover14 from "../../images/gallery/p-14.jpg";


export const list = [
    {
        cover: cover1,
        name: "Bridge Construction",
        price: "Different Designs",
        width: "large",
    },
    {
        cover: cover2,
        name: "River Sand",
        price: "Available",
        width: "large",
    },
    {
        cover: cover3,
        name: "Jebrongo Bridge",
        price: "Constructed by Us",
        width: "small",
    },
    {

        cover: cover4,
        name: "Pavement Slab ",
        price: "Different Sizes",
        width: "small",
    },
    {

        cover: cover5,
        name: "Spindles",
        price: "Different Designs",
        width: "large",
    },
    {

        cover: cover6,
        name: "Bush Stones",
        price: "All Sizes",
        width: "large",
    },
    {
        cover: cover7,
        name: "Culvert",
        price: "All Sizes",
        width: "large",
    },
    {
        cover: cover8,
        name: "Cabros",
        price: "Different Designs",
        width: "small",
    },
    {
        cover: cover9,
        name: "Pavement Slabs",
        price: "Different Sizes",
        width: "large",
    },
    {

        cover: cover10,
        name: "Road Construction",
        price: "all typest of roads",
        width: "large",
    },
    {
        cover: cover11,
        name: "Drainage Construction",
        price: "and associated works",
        width: "large",
    },
    {
        cover: cover12,
        name: "Cabros",
        price: "different designs",
        width: "small",
    },
    {
        cover: cover13,
        name: "Construction Blocks",
        price: "all sizes",
        width: "small",
    },
    {
        cover: cover14,
        name: "Hollow Blocks",
        price: "different sizes",
        width: "large",
    },
]
