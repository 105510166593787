import React from "react";
import "./welcome.css";

const Welcome = () => {
  return (
    <div className="welcome">
      <div className="leftt">
        <h2>Read Our Compay Story</h2>
        <p>
        Donder Company Limited thrives as a construction enterprise specializing in concrete works, civil engineering, and the design and manufacturing of various building materials. Situated opposite the School of the Deaf-Mwiyala along Kakamega - Webuye Road, the company has established itself as a reputable entity within the construction industry.
        </p>
        <button>Read More</button>
      </div>
      <div className="welcome-img">
        <img src="./images/welcome.jpg" alt="donder company" />
      </div>
    </div>
  );
};

export default Welcome;
