import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./lowertopbar.css";
import togle from "../../../../images/togle.png";
import untogle from "../../../../images/untogle.png";
function Lowertopbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => {
    setClick(click === false ? true : false);
  };

  return (
    <nav className="topbar">
      <div className="container">
        <div className="phone">
          <h2>DONDER COMPANY LIMITED</h2>
          <div className="togle">
            {click === true ? (
              <img
                src={untogle}
                alt=""
                onClick={(e) => {
                  e.preventDefault();
                  handleClick();
                }}
              />
            ) : (
              <img
                src={togle}
                alt=""
                onClick={(e) => {
                  e.preventDefault();
                  handleClick();
                }}
              />
            )}
          </div>
        </div>
        <ul className={click ? "lower active" : "lower"}>
          <li className="nav-item">
            <NavLink
              exact
              to="/"
              activeClassName="active"
              className="nav-links"
              onClick={handleClick}
            >
              Home
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              exact
              to="/aboutus"
              activeClassName="active"
              className="nav-links"
              onClick={handleClick}
            >
              About Us
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink
              exact
              to="/gallery"
              activeClassName="active"
              className="nav-links"
              onClick={handleClick}
            >
              Gallery
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              exact
              to="/contact"
              activeClassName="active"
              className="nav-links"
              onClick={handleClick}
            >
              Contact Us
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              exact
              to="/quotation"
              activeClassName="active"
              className="nav-links"
              onClick={handleClick}
            >
              Request Quotation
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Lowertopbar;
