import React from "react";
import "./quotation.css";
import Back from "../../components/reusable/back/Back";
import img from "../../images/screen/screen3.png";

const Quotation = () => {
  return (
    <>
      <Back
        name="Request Quotation"
        title="Get Competitive Prices"
        cover={img}
      />
      <div className="quotation">
        <div className="containerr">
          <h1>Request A Quotation</h1>
          <div className="content">
            <form>
              <div className="flex">
                <input type="text" placeholder="Name" />
                <input type="phone" placeholder="Phone" data-aos="flip-right" />
              </div>
              <input className="subject" type="text" placeholder="Product" />
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                data-aos="flip-down"
              ></textarea>
              <button data-aos="zoom-in-up">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Quotation;
