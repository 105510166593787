import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-subdiv">
        <div className="div">
          <h3>Company</h3>
          <p>Company Profile</p>
          <p>Vision & Mission</p>
          <p>Terms & Conditions</p>
          <p>Impact & Careers</p>
        </div>
        <div className="div">
          <h3>Services</h3>
          <p>Project registration</p>
          <p>Civil engineering works</p>
          <p>Building materials</p>
          <p>Construction machines</p>
        </div>
        <div className="div">
          <h3>Products</h3>
          <p>Stones & Blocks</p>
          <p>Moulds & Fencing Posts</p>
          <p>Sand & Ballast</p>
          <p>Tunnel drains & Grills</p>
        </div>
        <div className="div">
          <h3>Follow Us</h3>
          <p>LinkedIn</p>
          <p>Twitter</p>
          <p>Facebook</p>
          <p>Instagram</p>
        </div>
        <div className="div">
          <h3>Contact Us</h3>
          <p>Location</p>
          <p>RFQ</p>
          <p>Request Call</p>
          <p>Inquiries</p>
        </div>
      </div>

      <div className="copright">
        <span className="copright">
          © 2024 Donder Company Limited. All Rights Reserved.{" "}
          <a
            href="https://hisiadesigns.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            By Hisia Designs
          </a>
        </span>
      </div>
    </div>
  );
};

export default Footer;
