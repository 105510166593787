import React from "react";
import donderlogo from "../../../../images/donder-logo.png";
import "./uppertopbar.css";
const Uppertopbar = () => {
  return (
    <div className="uppertopbar">
      <div className="upper">
        <div className="logo">
          <img src={donderlogo} alt="" />
        </div>
        <div className="name">
          <h1 className="donder-name"> DONDER </h1>
          <h1 className="donder-name2"> COMPANY </h1>
          <h1 className="donder-name"> LIMITED </h1>
        </div>
        <div className="button">
          <input type="text" placeholder="Request Quatation" />
          <button>RFQ</button>
        </div>
      </div>
    </div>
  );
};

export default Uppertopbar;
