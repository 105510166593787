import React from "react";
import "./gallery.css";
import { list } from "./gallerydata";
import Back from "../../components/reusable/back/Back";
import img from "../../images/screen/screen3.png";
import Project from "../projects/Projects";

const Gallery = () => {
  return (
    <section className="gallerydisplay">
      <Back
        name="Our Products Gallery"
        title="Our Prevous Projects"
        cover={img}
      />
      <Project />
      <h2>Our Products Gallery</h2>
      <div className="container">
        {list.map((item, index) => (
          <div className={`boxx ${item.width}`} key={index}>
            <div className="img">
              <img src={item.cover} alt="" />
            </div>
            <div className="overlay">
              <h3>{item.name}</h3>
              <span>{item.price}</span>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Gallery;
