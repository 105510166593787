import "./projects.css";
import { list } from "./projectdata";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";

const Projects = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="projects">
      <h2>Our Clients & Projects</h2>
      <Slider {...settings}>
        {list.map((val, index) => {
          const { cover, name, description } = val;
          return (
            <div className="slide shadow2 " key={index}>
              <img src={cover} alt="" />
              <div className="onimg">
                <h3>{name}</h3>
                <p>{description}</p>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default Projects;
