import React from "react";
import "./contact.css";
import Back from "../../components/reusable/back/Back";
import img from "../../images/screen/screen3.png";
import watsapp from "../../images/socialmedia/watsapp.png";
import fb from "../../images/socialmedia/facebook.png";
import email from "../../images/socialmedia/email.png";
import twitter from "../../images/socialmedia/twitter.png";
import instagram from "../../images/socialmedia/instergram.png";
import linkedin from "../../images/socialmedia/linkedin.png";

const Contactus = () => {
  return (
    <>
      <Back name="Talk to Us" title="we do listen" cover={img} />
      <div className="contact">
        <h2>Social Media Handles</h2>
        <div className="handles flexible ">
          <div className="box">
            <a
              target="_blank"
              href={"https://wa.me/+254723774090?text=$hello"}
              rel="noreferrer"
            >
              <img src={watsapp} alt="rent a home" />
            </a>
            <span>watsapp</span>
          </div>

          <div className="box">
            <a
              target="_blank"
              href="https://twitter.com/DonaldImbugwa?t=UFSc68iLCDK_sb9s1FjM5A&s=01"
              rel="noreferrer"
            >
              <img src={twitter} alt="rent a home" />
            </a>
            <span>twitter</span>
          </div>

          <div className="box">
            <a
              target="_blank"
              href="https://m.facebook.com/dondercompany?mibextid=ZbWKwL"
              rel="noreferrer"
            >
              <img src={fb} alt="rent a home" />
            </a>

            <span>facebook</span>
          </div>
          <div className="box">
            <a
              target="_blank"
              href="https://instagram.com/dondercompany?igshid=YmMyMTA2M2Y="
              rel="noreferrer"
            >
              <img src={instagram} alt="rent a home" />
            </a>

            <span>instagram</span>
          </div>

          <div className="box">
            <a
              target="_blank"
              href="mailto:dondercompany2011@gmail.com?"
              rel="noreferrer"
            >
              <img src={email} alt="rent a home" />
            </a>

            <span>email</span>
          </div>

          <div className="box">
            <a
              target="_blank"
              href="https://www.linkedin.com/in/donder-company-ltd-2b3094272"
              rel="noreferrer"
            >
              <img src={linkedin} alt="rent a home" />
            </a>

            <span>linkedin</span>
          </div>
        </div>
        <div className="container mt">
          <h2>Write A Message</h2>
          <form className="shadow2">
            <h4>Fill The Form</h4> <br />
            <div>
              <input type="text" placeholder="Name" />
              <input type="text" placeholder="Email" />
            </div>
            <input type="text" placeholder="Subject" />
            <textarea cols="30" rows="10"></textarea>
            <button className="btn2">Submit Request</button>
          </form>
        </div>
        <h2>Location & Address</h2>
        <div className="handles flexible ">
          <div className="box">
            <h3>Mobile:</h3>
            <span>+254 723774090</span>
            <h3>Address:</h3>
            <span>BOX 2809 - 50100 Kakamega</span>
            <h3>Email:</h3>
            <span>dondercompany2011@gmail.com</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contactus;
