import React from "react";
import "./screen.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { NavLink } from "react-router-dom";

const Screen = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  return (
    <div className="screen">
      <Slider {...settings}>
        <div className="picandword pic1">
          <div className="container">
            <span>WELCOME TO</span>
            <h1>DONDER COMPANY LIMITED</h1>
            <span>HOME OF CONCRETE WORKS</span>
            <div className="screen-but">
              <button>
                <NavLink exact to="/aboutus" activeClassName="active">
                  Read More
                </NavLink>
              </button>
              <button>
                <NavLink exact to="/gallery" activeClassName="active">
                  View Projects
                </NavLink>
              </button>
            </div>
          </div>
        </div>
        <div className="picandword pic2">
          <div className="container">
            <span>WELCOME TO</span>
            <h1>DONDER COMPANY LIMITED</h1>
            <span>HOME OF CONCRETE WORKS</span>
            <div className="screen-but">
              <button>
                <NavLink exact to="/aboutus" activeClassName="active">
                  Read More
                </NavLink>
              </button>
              <button>
                <NavLink exact to="/gallery" activeClassName="active">
                  View Projects
                </NavLink>
              </button>
            </div>
          </div>
        </div>
        <div className="picandword pic3">
          <div className="container">
            <span>WELCOME TO</span>
            <h1>DONDER COMPANY LIMITED</h1>
            <span>HOME OF CONCRETE WORKS</span>
            <div className="screen-but">
              <button>
                <NavLink exact to="/aboutus" activeClassName="active">
                  Read More
                </NavLink>
              </button>
              <button>
                <NavLink exact to="/gallery" activeClassName="active">
                  View Projects
                </NavLink>
              </button>
            </div>
          </div>
        </div>
        <div className="picandword pic4">
          <div className="container">
            <span>WELCOME TO</span>
            <h1>DONDER COMPANY LIMITED</h1>
            <span>HOME OF CONCRETE WORKS</span>
            <div className="screen-but">
              <button>
                <NavLink exact to="/aboutus" activeClassName="active">
                  Read More
                </NavLink>
              </button>
              <button>
                <NavLink exact to="/gallery" activeClassName="active">
                  View Projects
                </NavLink>
              </button>
            </div>
          </div>
        </div>
        <div className="picandword pic5">
          <div className="container">
            <span>WELCOME TO</span>
            <h1>DONDER COMPANY LIMITED</h1>
            <span>HOME OF CONCRETE WORKS</span>
            <div className="screen-but">
              <button>
                <NavLink exact to="/aboutus" activeClassName="active">
                  Read More
                </NavLink>
              </button>
              <button>
                <NavLink exact to="/gallery" activeClassName="active">
                  View Projects
                </NavLink>
              </button>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default Screen;
