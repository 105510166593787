

import project1 from "../../images/projects/bungoma.png";
import project2 from "../../images/projects/kakamega.png";
import project3 from "../../images/projects/kenha.png";
import project4 from "../../images/projects/kilifi.png";
import project5 from "../../images/projects/kerra.png";
import project6 from "../../images/projects/national.png";
import project7 from "../../images/projects/vihiga.png";


export const list = [
    {
        id: 1,
        cover: project1,
        name: "County Government of Bungoma",
        description: "culvert, grading, and gravelling of KMTC SA church AI ",
    },
    {
        id: 2,
        cover: project2,
        name: "County Concil of Kakamega",
        description: "Road rehabilitation at savane primary school - 2012"

    },
    {
        id: 3,
        cover: project3,
        name: "KENHA Western Region",
        description: "Supply and installation of marker posts along mayoni mbwekas B12 road - 2017",
    },
    {
        id: 4,
        cover: project4,
        name: "County Government of Kilifi",
        description: "constructio of operation house - 2014"

    }, {
        id: 5,
        cover: project5,
        name: "KERRA Works Kakamega",
        description: "Mois Bridge - Tulienge(L6222) Road Likuyani Constituency",
    },
    {
        id: 6,
        cover: project6,
        name: "National Government of Kenya",
        description: "Construction of Sentry house, car park, main gate and installation of PVC water tank"

    }, {
        id: 7,
        cover: project7,
        name: "County Government of Vihiga",
        description: "Construction of Jebrongo Bridge - 2015 , Rehabilitation of Jeblebuk Road - 2014",
    },


];