import React from "react";
import "./whyus.css";

const Whyus = () => {
  return (
    <div className="whyus">
      <div className="heading">
        <h1>Why</h1>
        <h1 className="choose">Choose</h1>
        <h1>Us?</h1>
      </div>

      <p>
        At Donder Company we understand that we are providing a complex service,
        but that for you as the customer the process should be simple and
        hassle-free. We understand well the challenges that come with building
        in Kenya and the greater East Africa region, and it is our goal to
        ensure that the construction experience for you–our client–is as
        enjoyable, rewarding, and stress-free as possible.
      </p>

      <div className="sub-why">
        <div className="box">
          <img src="./images/h11.png" alt="" className="why-img" />
          <span>1. we deliver quality</span>
          <p>
            We only deliver quality products. We do not have alternative
            substandard versions of our products
          </p>
        </div>
        <div className="box">
          <img src="./images/h22.png" alt="" className="why-img" />
          <span>2. we are affordable</span>
          <p>
            We offer competitive and fair prices to our customers. We don't have
            hidden charges or exploits.
          </p>
        </div>
        <div className="box">
          <img src="./images/h33.png" alt="" className="why-img" />
          <span>3. we are accredited</span>
          <p>
            We are accredited by relevant proffesional bodies in Civil
            engineering and Construction
          </p>
        </div>
        <div className="box">
          <img src="./images/h44.png" alt="" className="why-img" />
          <span>4. we are reliable</span>
          <p>
            You can always count on us under all situations. We are consistent
            and available for our clients
          </p>
        </div>
      </div>
    </div>
  );
};

export default Whyus;
