import React from "react";
import "./back.css";

const Back = ({ name, title, cover }) => {
  return (
    <div className="back">
      <div className="cont">
        <p>{name}</p>
        <h1>{title}</h1>
      </div>
      <img src={cover} alt="" />
    </div>
  );
};

export default Back;
