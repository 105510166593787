import React from "react";
import "./numbers.css";

const Numbers = () => {
  return (
    <div className="numbers">
      <div className="numb">
        <h1>10</h1>
        <p>Accreditations</p>
      </div>
      <div className="numb">
        <h1>99</h1>
        <p>Succesfull Projects</p>
      </div>
      <div className="numb">
        <h1>200</h1>
        <p>Different Products</p>
      </div>
      <div className="numb">
        <h1>10000</h1>
        <p>Happy Cliets</p>
      </div>
    </div>
  );
};

export default Numbers;
