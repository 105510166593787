import React from "react";
import "./helpyou.css";

import Buy from "../../../images/helpyou/BuyAHome.svg";
import Rent from "../../../images/helpyou/RentAHome.svg";
import Neighbour from "../../../images/helpyou/Neighborhoods.svg";
import Project from "../../../images/helpyou/project.png";
import Metal from "../../../images/helpyou/metal.png";
import Machine from "../../../images/helpyou/machines.png";

const Helpyou = () => {
  const link = {
    concrete: "Hello%2C%20I%20am%20interested%20in%20your%20Concrete",
    civil: "Hello%2C%20I%20am%20interested%20in%20your%20Civilworks",
    construction:
      "Hello%2C%20I%20am%20interested%20in%20your%20constructionworks",
    project: "Hello%2C%20I%20am%20interested%20in%20your%20projectmanagement",
    metal: "Hello%2C%20I%20am%20interested%20in%20your%20metalworksproduct",
    machine: "Hello%2C%20I%20am%20interested%20in%20your%20machinesforhire",
  };
  return (
    <div className="helpyou">
      <h2> WE ARE DEALERS IN</h2>
      <div className="helpdiv ">
        <div className="ite">
          <img src={Buy} alt="buy a home" />
          <span>Concrete works</span>
          <p>
            Dealers in Culverts, Rings(all sizes), Slabs, Side slabs, IBD,
            Channels, Road cabs, Blocks(all sizes), Cabros(different designs),
            Spindles(all sizes).
          </p>

          <a
            target="_blank"
            href={`https://wa.me/+254723774090?text=${link.concrete}`}
            rel="noreferrer"
          >
            <button>Get Quote</button>
          </a>
        </div>
        <div className="ite">
          <img src={Rent} alt="rent a home" />
          <span>Civil engineering</span>
          <p>
            Construction of box culverts, Laying of surround, culvert works,
            Drainage works, Bridge construction, gravelliing, Road Maintainance
          </p>
          <a
            target="_blank"
            href={`https://wa.me/+254723774090?text=${link.civil}`}
            rel="noreferrer"
          >
            <button>Get Quote</button>
          </a>
        </div>
        <div className="ite">
          <img src={Neighbour} alt="advertise a home" />
          <span>Construction materials</span>
          <p>
            Supply of River Sand, Ballast(all sizes), Marram, Hardcore, Machine
            Cut Stones, Bush Stones, Cement, PVC etc
          </p>
          <a
            target="_blank"
            href={`https://wa.me/+254723774090?text=${link.construction}`}
            rel="noreferrer"
          >
            <button>Get Quote</button>
          </a>
        </div>
        <div className="ite">
          <img src={Project} alt="advertise a home" />
          <span>Project Management</span>
          <p>
            We are NCA registered - Reg No (1977R/0214) We offer project
            registration with NCA, NEMA and general project management
          </p>
          <a
            target="_blank"
            href={`https://wa.me/+254723774090?text=${link.project}`}
            rel="noreferrer"
          >
            <button>Get Quote</button>
          </a>
        </div>
        <div className="ite">
          <img src={Metal} alt="advertise a home" />
          <span>Metal Works</span>
          <p>
            We do metal works including, doors(different designs),
            windows(different designs), grills(different designs),
            gates(different designs)
          </p>
          <a
            target="_blank"
            href={`https://wa.me/+254723774090?text=${link.metal}`}
            rel="noreferrer"
          >
            <button>Get Quote</button>
          </a>
        </div>
        <div className="ite">
          <img src={Machine} alt="advertise a home" />
          <span>Machine Hire Services</span>
          <p>
            We have construction machines for hire: escavators, lorries,
            levellers, compacters, rollers, vibrators etc
          </p>
          <a
            target="_blank"
            href={`https://wa.me/+254723774090?text=${link.machine}`}
            rel="noreferrer"
          >
            <button>Get Quote</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Helpyou;
