import React from "react";
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Uppertopbar from "./components/common/topbar/uppertopbar/Uppertopbar";
import Lowertopbar from "./components/common/topbar/lowertopbar/Lowertopbar";
import Footer from "./components/common/footer/Footer";
import Homepage from './pages/homepage/Homepage';
import Contact from "./pages/contactus/Contact"
import Services from "./pages/services/Services"
import Gallery from "./pages/gallery/Gallery"
import Quotation from "./pages/quotation/Quotation"
import Scrolltop from "./components/reusable/Scroll/Scroll";



function App() {
  return (
    <div className="main">
      <Router>
        <Uppertopbar />
        <Lowertopbar />
        <Scrolltop />
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route exact path="/home" element={<Homepage />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/aboutus" element={<Services />} />
          <Route exact path="/gallery" element={<Gallery />} />
          <Route exact path="/quotation" element={<Quotation />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;